<template>
  <b-card>
    <div>
      <!-- search input -->
      <div class="custom-search d-flex align-items-center justify-content-end">
        <div
          class="d-flex flex-column flex-sm-row align-items-center mb-1 justify-content-around"
        >
          <b-form-input
            v-on:keyup="onSearch"
            v-model.lazy="searchTerm"
            placeholder="Search..."
            type="text"
            class="d-inline-block mr-sm-1 mb-1 mb-sm-0"
          />
          <template
            v-if="$permissionAbility(ACCOUNT_DEPOSIT_CREATE, permissions)"
          >
            <b-button
              class="flex-shrink-0"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              v-on:click="showModal"
            >
              Deposit
            </b-button>
          </template>
        </div>
      </div>

      <!-- table -->

      <vue-good-table
          styleClass="vgt-table table-custom-style striped"
        mode="remote"
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-column-filter="onColumnFilter"
        @on-per-page-change="onPerPageChange"
        :totalRows="totalRecords"
        :isLoading.sync="isLoading"
        :rows="rows"
        :sort-options="{
          enabled: false,
          multipleColumns: true,
          initialSortBy: [
            { field: 'id', type: 'desc' },
            { field: 'title', type: 'desc' },
            { field: 'created_at', type: 'desc' },
          ],
        }"
        :columns="columns"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
      >
        <template slot="table-row" slot-scope="props">
          <!-- From Account -->
          <template v-if="props?.column?.field === 'format_title'">
            <span><b>{{ props?.row?.title }}</b></span>
          </template>
          <template v-if="props?.column?.field === 'format_account'">
            <span>{{ props?.row?.account?.data?.name }}</span>
          </template>
          <template v-if="props?.column?.field === 'format_amount'">
            <FormatCurrency :amount="props.row?.amount" :currency="props?.row?.currency" />
          </template>

          <template v-if="props?.column?.field === 'format_currency'">
            <FormatCurrency :type="'currency'" :amount="props.row?.amount" :currency="props?.row?.currency" />
          </template>

          <template v-if="props?.column?.field === 'format_remarks'">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              v-on:click="() => showDescriptionModal(props.row?.remarks)"
              class="btn-sm"
            >
              <feather-icon icon="InfoIcon" />
            </b-button>
          </template>
          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Showing 1 to </span>
              <b-form-select
                v-model="pageLength"
                :options="['10', '25', '50', '100', '500']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </div>
    <b-modal
      id="description-modal"
      centered
      title="Remarks"
      hide-footer
      @hidden="hiddenDescriptionModal"
      no-close-on-backdrop
    >
      <b-card-text>
        {{ modalDescription }}
      </b-card-text>
    </b-modal>
    <b-modal
      id="modal-accounts-deposit-form"
      centered
      title="Create"
      hide-footer
      @hidden="hiddenModal"
      no-close-on-backdrop
    >
      <validation-observer ref="accountDepositValidation">
        <b-form v-on:submit.prevent="validationForm">
          <!-- name -->
          <b-form-group label="Title " label-for="title" class="required-label">
            <validation-provider
              #default="{ errors }"
              name="title"
              vid="title"
              rules="required|max:255"
            >
              <b-form-input
                id="title"
                type="text"
                v-model="title"
                :state="errors.length > 0 ? false : null"
                placeholder="Title"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <!-- From account -->
          <b-form-group label="Account " label-for="account" class="required-label">
            <ValidationProvider
              name="account"
              v-slot="{ errors }"
              vid="account_id"
              rules="required"
            >
              <!--  -->
              <v-select
                id="account"
                placeholder="Choose an Account"
                v-model="selectAccountId"
                :options="accountsOption"
                :reduce="(option) => option.id"
                label="name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>

          <!-- Amount-->
          <b-form-group label="Amount " label-for="amount" class="required-label">
            <validation-provider
              #default="{ errors }"
              name="amount"
              vid="amount"
              rules="required|integer"
            >
              <b-form-input
                id="amount"
                type="number"
                v-model="amount"
                :state="errors.length > 0 ? false : null"
                placeholder="Enter Amount"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- details -->
          <b-form-group label="Remarks " label-for="Remarks" class="required-label">
            <validation-provider
              #default="{ errors }"
              name="remarks"
              vid="remarks"
              rules="required"
            >
              <b-form-textarea
                id="remarks"
                v-model="remarks"
                :state="errors.length > 0 ? false : null"
                placeholder="Add Remarks"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- loading button -->
          <template v-if="loading">
            <b-button class="float-right" variant="primary" disabled>
              <b-spinner small />
              Loading...
            </b-button>
          </template>

          <!-- submit button -->
          <template v-else>
            <b-button
              type="submit"
              class="float-right"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
            >
              Submit
            </b-button>
          </template>
        </b-form>
      </validation-observer>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BForm,
  BModal,
  BFormTextarea,
  BSpinner,
  VBTooltip,
  BCardText,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, max, integer } from "@validations";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
import { ACCOUNT_DEPOSIT_CREATE } from "@/helpers/permissionsConstant";
import FormatCurrency from '@/layouts/components/FormatCurrency.vue'

export default {
  name: "AccountDepositView",
  components: {
    FormatCurrency,
    BCardText,
    BForm,
    BButton,
    BCard,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BSpinner,
    BFormTextarea,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      ACCOUNT_DEPOSIT_CREATE,
      title: "",
      modalDescription: "",
      selectAccountId: "",
      accountsOption: [],
      amount: "",
      remarks: "",
      pageLength: 10,
      columns: [
        {
          label: "Title",
          field: "format_title",
        },
        {
          label: "Account",
          field: "format_account",
          sortable: false,
        },
        {
          label: "Currency",
          field: "format_currency",
          sortable: false,
        },
        {
          label: "Amount",
          field: "format_amount",
          sortable: false,
        },
        {
          label: "Remarks",
          field: "format_remarks",
          sortable: false,
        },

        {
          label: "Created On",
          field: "created_at",
        },
      ],
      rows: [],
      searchTerm: "",
      delayTimer: null,
      isLoading: false,
      loading: false,
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          { field: "id", type: "desc" },
          { field: "name", type: "desc" },
          { field: "created_at", type: "desc" },
        ],
        page: 1,
        perPage: 10,
      },
    };
  },

  computed: {
    ...mapGetters({
      permissions: "userModule/getPermissions",
    }),
  },

  async created() {
    try {
      const [getActiveAccounts] = await Promise.all([this.getActiveAccounts()]);

      //active accounts
      this.accountsOption = (getActiveAccounts?.data?.data || []).map(
        (item) => {
          const name = `${item?.name} (${item?.currency} ${item?.balance})`;
          const id = item?.id;

          return {
            name,
            id,
          };
        }
      );
    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error 2",
          icon: "BellIcon",
          variant: "danger",
          text: error?.response?.data?.message,
        },
      });
    }
  },

  methods: {
    showDescriptionModal(remarks) {
      if (remarks) {
        this.modalDescription = remarks;
      } else {
        this.modalDescription = "N/A";
      }

      this.$bvModal.show("description-modal");
    },
    hiddenDescriptionModal() {
      this.$bvModal.hide("description-modal");
    },
    showModal() {
      this.$bvModal.show("modal-accounts-deposit-form");
    },
    hiddenModal() {
      this.$bvModal.hide("modal-accounts-deposit-form");
      this.resetModal();
    },
    resetModal() {
      this.title = "";
      this.selectAccountId = "";
      this.amount = "";
      this.remarks = "";
    },
    async getActiveAccounts() {
      return await this.$api.get("api/accounts/active/all");
    },
    onSearch() {
      if (this.delayTimer) {
        clearTimeout(this.delayTimer);
        this.delayTimer = null;
      }

      this.delayTimer = setTimeout(() => {
        this.loadItems();
      }, 1000);
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams({
        sort: params,
      });
      this.loadItems();
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },
    async getAccountDeposits(params) {
      return await this.$api.get("api/account-deposits?include=account", {
        params: {
          show: params.show,
          page: params.page,
          sort: params.sort,
          q: params.q,
        },
      });
    },
    async loadItems() {
      try {
        const [getAccountDeposits] = await Promise.all([
          this.getAccountDeposits({
            show: this.serverParams.perPage,
            page: this.serverParams.page,
            sort: this.serverParams.sort,
            q: this.searchTerm,
          }),
        ]);

        const data = getAccountDeposits?.data?.data;
        const meta = getAccountDeposits?.data?.meta;
        this.totalRecords = meta?.pagination?.total;
        this.rows = data;
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },
    validationForm: async function () {
      this.$refs.accountDepositValidation.validate().then(async (success) => {
        if (success) {
          try {
            this.$refs.accountDepositValidation.reset();
            this.loading = true;
            await this.$api.post(
              "/api/account-deposits",

              {
                account_id: this.selectAccountId,
                title: this.title,
                amount: this.amount,
                remarks: this.remarks,
              }
            );
            this.loading = false;

            this.hiddenModal();

            const [getActiveAccounts] = await Promise.all([
              this.getActiveAccounts(),
              this.loadItems(),
            ]);

            //active accounts
            this.accountsOption = (getActiveAccounts?.data?.data || []).map(
              (item) => {
                const name = `${item?.name} (${item?.balance})`;
                const id = item?.id;

                return {
                  name,
                  id,
                };
              }
            );

            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "BellIcon",
                variant: "success",
                text: "Successfully Completed",
              },
            });
          } catch (error) {
            this.loading = false;
            if (error?.response?.data?.message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  variant: "danger",
                  text: error?.response?.data?.message,
                },
              });
            }

            if (error?.response?.data?.errors) {
              this.$refs.accountDepositValidation.setErrors(
                error?.response?.data?.errors
              );
            }
          }
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
.table-custom-style {
  font-size: 14px !important;
  white-space: nowrap !important;
  min-height: 140px !important;
  tr,
  th,
  td {
    vertical-align: left !important;
    text-align: left !important;
  }
}
</style>
